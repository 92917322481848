var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.actions)?_c('Confirmacion',{attrs:{"pregunta":_vm.consultaDialog,"consulta":_vm.preguntaConsulta,"loading":_vm.loadingPregunta},on:{"si":_vm.confirmacionAceptada,"no":function($event){_vm.consultaDialog=false}}}):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[(_vm.add)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('NuevaDocencia',{attrs:{"urlAdd":_vm.urlAdd,"globalAdd":_vm.globalAdd},on:{"creado":_vm.creado}})],1)],1):_vm._e(),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.select),expression:"select"}]},[_vm._v("Docencia Selecccionada : "+_vm._s(_vm.selectedIds.length))]),_c('v-data-table',{attrs:{"headers":_vm.computedHeaders,"items":_vm.desserts,"item-key":"id","server-items-length":_vm.totalDesserts,"hide-default-footer":_vm.hiddenFoter,"noDataText":"No hay ninguna Docencia Registrada para el Abogado","mobile-breakpoint":100,"calculate-widths":"","show-select":_vm.select,"loading":_vm.loadingTable,"loading-text":"Cargando Datos"},scopedSlots:_vm._u([{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [(item.verificacion==null)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.eliminarExperiencia(item)}}},[_vm._v(" mdi-trash-can ")]):_vm._e()]}},{key:"item.verificacion",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.colorEstado(item.verificacion),"text-color":"white"}},[_vm._v(_vm._s(_vm.textoEstado(item.verificacion)))])]}},{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNombreParametro(item,'tipo'))+" ")]}},{key:"item.area",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNombreParametro(item,'area'))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }